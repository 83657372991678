import React, {Component} from "react"
import styled from "styled-components"
import {Row, Col, Container} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

import PageHeader from "components/shared/PageHeader"
import Layout from "components/Layout/Layout"


import {media} from "utils/Media"

const TermsContent = styled.div`
.header {
    margin: 0 auto;
    text-align: center;
    h1 {
        font-family: ${props => props.theme.font.family.special};
            color: ${props => props.theme.colors.navy};
            text-align: center;
            margin: 2rem 0!important;
            @media ${media.xl} {
                font-size: 3rem;
            }
    }
    
}
    h3 {
        color: ${props => props.theme.colors.navy};
        text-align: center;
    }
    
    a {
        font-weight: bold;
        color: ${props => props.theme.colors.navy};
    }
`

const Clouds = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${props => props.CloudsXs}) no-repeat right top;
    background-size: 100%;
    z-index: 2;
    
    @media ${media.sm} {
        background: url(${props => props.CloudsSm}) no-repeat right center;
        background-size: contain;
        /*height: 700px;
        margin: -4rem 0 0;*/
    }
    
    @media ${media.md} {
        /*height: 750px;
        margin: -4rem 0 0;*/
        background: url(${props => props.CloudsMd}) no-repeat right center;
        background-size: contain;
    }
    
    @media ${media.lg} {
        background: url(${props => props.CloudsLg}) no-repeat right center;
        background-size: cover;
       /* height: 750px;
        margin: 0;*/
    }
    
    @media ${media.xl} {
        background: url(${props => props.CloudsXl}) no-repeat right center;
        background-size: 100%;
        /*height: 1025px;
        margin: -4rem 0 0;    */
    }
    
    /* h1, h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 2rem 0 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    } */

`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {                
                CloudsXs: file(relativePath: { eq: "HomePage/clouds-320.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 320, quality: 100)
                    }
                }
                
                CloudsSm: file(relativePath: { eq: "HomePage/clouds-768.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100)
                    }
                }
                
                CloudsMd: file(relativePath: { eq: "HomePage/clouds-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "HomePage/clouds-1200.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                CloudsXl: file(relativePath: { eq: "HomePage/clouds-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                CloudsReversedSm: file(relativePath: { eq: "Article1/clouds-top-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CloudsReversedLg: file(relativePath: { eq: "Article1/clouds-top-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <TnC data={data}/>
        )}
    />
)

class TnC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeId: "",
            contentHeight: 0
        }
    }

    render() {
        return (
            <Layout pageName="competitionterms" slug="competitionterms" title="Prize Draws and Competitions">

                <PageHeader />

                <TermsContent>
                    <Clouds
                        CloudsXs={this.props.data.CloudsXs.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsSm={this.props.data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsMd={this.props.data.CloudsMd.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsLg={this.props.data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsXl={this.props.data.CloudsXl.childImageSharp.gatsbyImageData.images.fallback.src}
                    >
                        <Container fluid={true}>
                            <Container>
                                <div className="header">
                                    <h1>
                                        General Terms &amp; Conditions for Prize Draws and Competitions
                                    </h1>
                                </div>
                                {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                                {/* <HeadingSpecial headingTag="h1"
                                                title="General Terms & Conditions for Prize Draws and Competitions"/> */}
                                <Row>
                                    <Col xs={12}>
                                        <h3>SPOT THE ICE TRUCK: ICE CO LIMITED &amp; DISNEY&rsquo;S FROZEN</h3>
                                        <p>By participating you agree to the requirements set out in the Competition Rules, the following General Terms and Conditions and all promotional materials.</p>
                                        <ol start="1" type="1">
                                            <li><strong><u>Eligibility.</u></strong></li>
                                            <ol start="1" type="a">
                                                <li>Entrants must meet the Entry Restrictions.</li>
                                                <li>Not open to employees (or their immediate families) of the Promoters, their agencies or anyone else professionally associated with this competition.</li>
                                                <li>Maximum one entry per person unless otherwise stated. Entries via agents or third parties or use of multiple identities and email addresses are not permitted. Use of automated entries, votes or other programs is prohibited and all such entries (or votes) will be disqualified.</li>
                                                <li>Entries must be completed by the Closing Date.</li>
                                                <li>The Promoters reserve the right at any time to request proof of identity, to verify eligibility conditions, or to award any prize to the winner in person or a substitute.</li>
                                            </ol>
                                            <li><strong><u>Personal Information. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>Your personal information will be used in accordance with The Promoters&rsquo; current published Privacy Policy (click <a href="https://disneyprivacycenter.com/" target="_blank">here</a> to view Disney&rsquo;s Policy and click here to view <a href="https://www.theiceco.co.uk/privacy/">The Ice Co&rsquo;s</a>Privacy Policy) and may be passed to our agents to administer the competition or send out prizes. We may also use your personal information to promote the competition and Disney products and services (including the products and services of any partner sponsoring or otherwise participating in the competition) in any media including, without limitation, TV, online and publishing channels and social media platforms.</li>
                                                <li>Personal information will only be used for direct marketing purposes if you have consented to receive future marketing from the relevant Promoter (you may have already done this if you have registered your personal information with the Promoters previously).</li>
                                                <li>By entering this competition, entrants agree that their information may be used by the Promoters to administer the promotion and winners consent to give their name and county for the winners&rsquo; list</li>
                                            </ol>
                                            <li><strong><u>Winner Selection.</u></strong></li>
                                            <ol start="1" type="a">
                                                <li>The winner(s) will be selected in a random draw to take place within 10 working days of the closing date from all correct/valid entries received.</li>
                                                <li>The Promoters reserve the right to ask winners to sign a written release, request proof of identity and verify the entry before awarding any prize.</li>
                                                <li>The Promoters&rsquo; decision to select any winner or runner-up is final and we reserve the right not to correspond on any matter.</li>
                                            </ol>
                                            <li><strong><u>Prize Confirmation &amp; Acceptance. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>If at any time (i) we require a response from any winner before awarding any prize and no response is received after we have made reasonable efforts to make contact and within a reasonable period of time (or by the dates set out in the Competition Rules), or (ii) a winner declines to accept the prize; or (iii) the requested documentation stated in the Competition Rules is not received Disney in time; or (iv) the winner becomes unavailable; The Promoters reserve the right to withdraw the prize entitlement and award the prize to a substitute winner chosen at the same time as the first name(s) were selected or judged to be a winner.</li>
                                                <li>Any prize which is returned or cannot be delivered will be used in the Promoters&rsquo; sole discretion for another competition or given to charity. Disney has no responsibility to anyone who has not responded.</li>
                                            </ol>
                                            <li><strong><u>Prize Fulfilment. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>No cash or other alternative prizes are available, except that in the event of circumstances outside of their control the Promoters (and/or any sponsor partner providing any prize) reserve the right to substitute a similar prize of equal or greater value. Prizes cannot be transferred or sold, under any circumstance but can be transferred if a family member is entering on behalf of a child.</li>
                                                <li>Unless otherwise stated in the prize description, winners are solely responsible for all personal additional costs and expenses associated with claiming the prize.</li>
                                            </ol>
                                            <li><strong><u>Cancellation or Disqualification. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>The Promoters reserve the right to disqualify:</li>
                                                <ol start="1" type="i">
                                                    <li>late, misdirected, incomplete, corrupted, lost, illegible or invalid entries;&nbsp;</li>
                                                    <li>entries where appropriate parental authorisation or a requested release form is or was not provided; or</li>
                                                    <li>entrants whose conduct may bring either of the Promoters into disrepute (as the Promoters may decide in their absolute discretion).</li>
                                                </ol>
                                                <li>The Promoters reserve the right to modify, suspend, cancel or terminate the competition or extend or resume the entry period or disqualify any participant at any time without giving advance notice and will do so if it cannot be guaranteed that the competition can be carried out fairly or correctly for technical, legal or other reasons or if the Promoters suspect that any person has been manipulating entries or the results, or has provided false information or has acted unethically in any other way.</li>
                                            </ol>
                                            <li><strong><u>Promoters&rsquo; Liability. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>The Promoters cannot guarantee continuous, uninterrupted or secure access to the websites (including their Instagram, Facebook, Twitter or other social media pages) and are not responsible for any disruption due to technical problems or otherwise due to events outside of their reasonable control. In the event of technical problems, entries or votes will be void and the winner will be selected by a panel of judges or such other fair method of selection.</li>
                                                <li>The Promoters have arranged this competition in good faith but, to the fullest extent allowed by law, accept no responsibility for loss or damages as a result of anyone participating in the competition or any aspect of any prize awarded.</li>
                                                <li>The Promoters are not responsible for and do not endorse or guarantee, the opinions, views, advice or recommendations posted on their websites (including their Instagram, Facebook, Twitter or other social media pages).</li>
                                            </ol>
                                            <li><strong><u>Entry through your Facebook account</u></strong></li>
                                        </ol>
                                        <p>If the competition requires entry via Facebook any liability incurred by Facebook shall be governed by, and limited to, the applicable provisions of the Facebook Statement of Rights and Responsibilities. This promotion is not sponsored, endorsed, administered or associated with Facebook. By entering you are providing your information to the Promoters or their agents and not to Facebook. We (and/or our agents) will collect your unique Facebook User ID so that we can administer your entry i.e. record your entry and randomly select a winner.</p>
                                        <ol start="9" type="1">
                                            <li><strong><u>Email safety. </u></strong></li>
                                        </ol>
                                        <p>In the event that the Promoters wish to contact you in connection with your entry we will do so via an email ending in 'disney.com' or in &lsquo;theiceco.co.uk&rsquo; or by phone with a follow up e-mail. Any email which appears to come from the Promoters but using a different ending (e.g. hotmail.com, yahoo.com etc) should be treated with suspicion and forwarded to Customer Services. DO NOT respond or act on any e-mail if you are not sure that it comes from the Promoters. If you are in any doubt please contact Customer Services.</p>
                                        <ol start="10" type="1">
                                            <li><strong><u>Publicity &amp; Filming. </u></strong></li>
                                            <ol start="1" type="a">
                                                <li>Winners and/or runners-up may be required to participate in related publicity without further payment or permission but you agree that the Promoters may publish first name or surname and initial, age, town/city and/or likeness.</li>
                                                <li>If filming is required the winners may be asked to consent to being filmed (the &ldquo;<strong><em>Contribution</em></strong>&rdquo;). If consent is given the winner shall assign to the Promoters all rights of whatsoever nature, including all copyright in respect of the Contribution and grant all further consents or licences necessary to enable the fullest use of the Contribution throughout the universe and without any time limit by any and all means in any and all media, whether now known or hereafter developed, without liability or acknowledgement.</li>
                                            </ol>
                                            <li><strong><u>Winners' details. </u></strong></li>
                                        </ol>
                                        <p>The Promoters will, on request, make available information that indicates that a valid award took place. For information regarding this competition send a stamped and addressed envelope to the Promoters quoting &ldquo;Winners List&rdquo; and the name of the competition.</p>
                                        <ol start="12" type="1">
                                            <li>No purchase necessary.</li>
                                            <li>The laws of England and Wales apply and any disputes will be dealt with in the English courts only.</li>
                                        </ol>
                                        <p>© Disney</p>
                                    </Col>
                                </Row>

                                {/*</ScrollAnimation>*/}
                            </Container>
                        </Container>
                    </Clouds>


                </TermsContent>

            </Layout>
        )
    }
}

export default Query